exports.domain = "https://npteer.sgteer.com";
//exports.domain = "http://localhost/app";
//App URL
//exports.domain = "http://192.168.29.10:8100";

//Currency
exports.currency="INR";
exports.currencySign="₹";

//Version
exports.version="1.2.5";

//APP API secert
exports.APP_SECRET="MxwOn6gNJs="; //Your app secret - same as in the .env file in your web project 
