import React, { useState, useEffect } from 'react';
import {
  ActivityIndicator,
  View,
  StyleSheet,
  Image,
} from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';

import { useTheme } from 'react-native-paper';
import { versionChecker } from "../Services/api"
import { Images } from "../Constants";
import config from '../config';

export const SplashScreen = ({ navigation }) => {
  const [animating, setAnimating] = useState(true);
  const theme = useTheme();

  const [data, setData] = React.useState([]);

  const fetchData = async () => {
    const response = await versionChecker();
    setData(response.data)
    if(config.version.localeCompare(response.data.version)===0){
      setTimeout(() => {
        setAnimating(false);
        AsyncStorage.getItem("token").then((value) => {
          if (value === null) {
            navigation.replace('Auth')
          }else{
            navigation.replace('DrawerNav')
          }
        })
      }, 3000);      
    }else{
      navigation.replace('Version')
    }
  }
  React.useEffect(() => {
    fetchData();
  }, [])

  return (
    <View style={[styles.container,{backgroundColor:theme.colors.primary}]}>
        <Image
          source={Images.wLogo}
          style={{width:250,height:100}}
        />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%'
  },
  activityIndicator: {
    alignItems: 'center',
    height: 80,
  },
});
